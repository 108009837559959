<template>

    <div class="overview-content-view ml-0 pl-0" v-if="contactLeases.length > 0" :class="{'mb-6' : closed_leases_panel.length === 0, 'mt-2': (delinquencies.length == 0 && accessConflict.length == 0 && !showAutoReconcilation && !notification), 'pr-0' :  $vuetify.breakpoint.width >= 480 && $vuetify.breakpoint.width <= 837}">
        

        <!-- <v-row v-if="showGateError" style="flex:none;">
            <v-col class="ma-0 pa-0 pt-2 pl-4" :class="{'pr-9' : showAside && !$vuetify.breakpoint.xs, 'pr-3' : $vuetify.breakpoint.xs}">
                <hb-notification
                    type="caution"
                    @close="closeAutoReconcile"
                    title-off
                >
                    <span class="font-weight-medium">
                        This account needs attention:
                    </span>
                    <span>
                        The tenant has open and/or past due invoices(s). Please use the
                        <br v-if="$vuetify.breakpoint.width <= 1515" />
                        tenant's Reserved Balance and/or Credit Balance to reconcile the invoice(s).
                    </span> 
                    <template v-slot:actions>
                        <hb-btn small color="primary" @click="showAutoReconcileConfirmation = true">
                            Auto-Reconcile
                        </hb-btn>
                    </template>
                </hb-notification>
            </v-col>
        </v-row> -->

        <div class="my-0 pl-4"  :class="[responsiveClasses, {'pb-2': (delinquencies.length > 0 || accessConflict.length > 0 || showAutoReconcilation || notification)}]">
            <v-row v-if="notification" class="mt-3 flex-none">
                <hb-notification
                    v-model="notification"
                    v-if="notification"
                    :type="type"
                > 
                    {{ message }}
                </hb-notification>
            </v-row>
               
            <v-row v-for="(delinquency, i) in delinquencies" :key="i" class="mt-3 flex-none">
                <hb-notification
                    notDismissable
                    type="caution"
                    :title="delinquency | delinquency_title"
                    :left-cols="$vuetify.breakpoint.mdAndDown ? 8 : 9"
                    :right-cols="$vuetify.breakpoint.mdAndDown ? 4 : 3"
                > 
                    {{delinquency | delinquency_message}}
                    <template v-slot:condensedActions>
                        <hb-tooltip :disabled="delinquency.is_property_level_paused" max-width="200">
                            <template v-slot:item>
                                <hb-menu
                                    options
                                    options-hover-type="caution"
                                    :disabled= "!delinquency.is_property_level_paused && delinquency.status === 'paused'"
                                >
                                    <v-list>
                                        <v-list-item v-if="delinquency.status == 'active'" @click="pauseDelinquency(delinquency)" :ripple="false">
                                            <v-list-item-title>Pause Delinquency Automation</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="delinquency.status == 'paused'" @click="resumeDelinquency(delinquency)" :ripple="false">
                                            <v-list-item-title>Resume Delinquency Automation</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </hb-menu>
                            </template>
                            <template v-slot:body>
                                {{ AUTOMATION.PROPERTY_LEVEL_PAUSE_TOOLTIP_TEXT }}
                            </template>
                        </hb-tooltip>
                    </template>
                    <template v-slot:actions>
                        <hb-btn v-if="delinquency.status == 'active'" color="secondary" small @click="pauseDelinquency(delinquency)">Pause Delinquency Automation</hb-btn>
                        <hb-tooltip v-if="delinquency.status == 'paused'" :disabled="delinquency.is_property_level_paused" max-width="200">
                            <template v-slot:item>
                                <hb-btn v-if="delinquency.status == 'paused'" :disabled="!delinquency.is_property_level_paused" color="secondary" small @click="resumeDelinquency(delinquency)">Resume Delinquency Automation</hb-btn>
                            </template>
                            <template v-slot:body>
                                {{ AUTOMATION.PROPERTY_LEVEL_PAUSE_TOOLTIP_TEXT }}
                            </template>
                        </hb-tooltip>
                    </template>
                </hb-notification>
            </v-row>

        
            <v-row v-for="(lease, i) in accessConflict" :key="i" class="mt-3 flex-none">
                <hb-notification
                    notDismissable
                    type="error"
                    :title="'Space ' + lease.Unit.number + ' should be overlocked status'"
                > as per your delinquency shedule but the access to this space is active. Please visit 'Gate Access' section to suspend the access.
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="error"
                        >
                            <v-list>
                                <v-list-item :to="contact.id + '/gate-access'" :ripple="false">
                                    <v-list-item-title>Gate Access</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <hb-btn color="secondary" small :to="contact.id + '/gate-access'">Gate Access</hb-btn>
                    </template>
                </hb-notification>
            </v-row>
    
            <v-row v-for="(lease, index) in auctionLeases" :key="index" class="mt-3 flex-none">
                <hb-notification
                    type="error"
                    mdi-code="mdi-custom-auction"
                    title-off
                    notDismissable
                    :left-cols="$vuetify.breakpoint.mdAndDown ? 8 : 9"
                    :right-cols="$vuetify.breakpoint.mdAndDown ? 4 : 3"
                >
                    <span class="font-weight-medium">
                        <template v-if="lease.auction_status == 'auction_day'">Auction Closed: </template>
                        <template v-else-if="lease.auction_status == 'auction_payment'">Auction Information: </template>
                        <template v-else>Auction:</template>
                    </span> {{getAuctionMessage(lease)}}
                    <span class="font-weight-medium">{{ getProtectedPropertyItemsMessage(lease) }}</span>
                    <template v-slot:condensedActions>
                        <hb-menu
                            options
                            options-hover-type="error"
                        >
                            <v-list>
                                <v-list-item @click="showAuctionModal(lease)" :ripple="false">
                                    <v-list-item-title>{{ getAuctionAction(lease) }}</v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-if="lease.auction_status == 'move_out' && (checkPermissionProperties({label: 'move_out_lease', properties: [propertyView]}) || nationalAccount)"
                                    @click="emitMoveOut(lease)"
                                >
                                    <v-list-item-title>Move-Out</v-list-item-title>
                                </v-list-item>

                                <v-list-item
                                    v-else-if="lease.auction_status == 'move_out' && !checkPermissionProperties({label: 'move_out_lease', properties: [propertyView]})"
                                    disabled
                                >
                                    <v-list-item-title>Move-Out</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:actions>
                        <div class="d-flex align-center">
                            <hb-link :style="lease.auction_status == 'move_out' ? 'position:relative;top:6px;' : 'position:relative;top:10px;'" class="pr-3" @click="showAuctionModal(lease)">{{ getAuctionAction(lease) }}</hb-link>
                            <hb-btn v-if="lease.auction_status == 'move_out' && (checkPermissionProperties({label: 'move_out_lease', properties: [propertyView]}) || nationalAccount)" small color="secondary" @click="emitMoveOut(lease)">Move-Out</hb-btn>
                            <hb-tooltip v-else-if="lease.auction_status == 'move_out' && !checkPermissionProperties({label: 'move_out_lease', properties: [propertyView]})">
                                <template v-slot:item>
                                    <hb-btn small color="secondary" disabled>Move-Out</hb-btn>
                                </template>
                                <template v-slot:body>
                                    User is not allowed to perform move out on this property
                                </template>
                            </hb-tooltip>
                        </div>
                    </template>
                </hb-notification>
            </v-row>
            <v-row v-if="showAutoReconcilation" class="mt-3 flex-none">
                <hb-notification
                    type="caution"
                    @close="closeAutoReconcile"
                    title-off
                >
                    <span class="font-weight-medium">
                        This account needs attention:
                    </span>
                    <span>
                        The tenant has open and/or past due invoices(s). Please use the
                        <br v-if="$vuetify.breakpoint.width <= 1515" />
                        tenant's Reserved Balance and/or Credit Balance to reconcile the invoice(s).
                    </span>
                    <template v-slot:condensedActions>
                    <hb-menu
                        options
                        options-hover-type="caution"
                    >
                        <v-list>
                            <v-list-item @click="showAutoReconcileConfirmation = true" :ripple="false">
                                <v-list-item-title>Auto-Reconcile</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
                <template v-slot:actions>
                    <hb-btn small color="primary" @click="showAutoReconcileConfirmation = true">
                        Auto-Reconcile
                    </hb-btn>
                </template>
                </hb-notification>
            </v-row>
        </div>
        <hb-modal confirmation show-help-link v-model="showAutoReconcileConfirmation" v-if="showAutoReconcileConfirmation" size="medium" title="Auto Reconcile" @close="showAutoReconcileConfirmation = false">
            <template v-slot:content>
                <div class="pa-6">
                  Tenant's Reserved Balance/Credit Balance (${{ totalCredits + totalReservedBalance }}) will be applied to open and/or past due invoices.
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn @click="autoReconcile" color="primary">Confirm</hb-btn>
            </template>
        </hb-modal>

        <v-divider v-if="isAuctionLeasePresent" class="mt-4 ml-1"></v-divider>
 
        <div class="ma-0 pa-0 pl-1" :class="{'pr-3': $vuetify.breakpoint.width > 480 && $vuetify.breakpoint.width <= 837}" cols="12" v-if="$vuetify.breakpoint.width <= 837">
            <hb-tabs-responsive background-color="#F9FAFB" class="hb-text-light"
                    color="#101318" v-model="right_column_view" show-arrows>
                    <template v-slot:tabs>
                        <tab v-for="(tab, i) in tenantProfileTabs" :hide="tab.hide || false" :label="tab.label" :key="tab.key"
                            class="text-capitalize" :badgeCount="tab.badgeCount || null"
                            :badgeColor="tab.badgeColor || null" :ripple="false">
                        </tab>
                    </template>
            </hb-tabs-responsive>
            <v-divider class="mb-2"></v-divider>
        </div>
        <!-- <v-row :class="{'pr-9' : $vuetify.breakpoint.width > 1460, 'px-3' : $vuetify.breakpoint.xs, 'pr-6' : $vuetify.breakpoint.width >= 480 && $vuetify.breakpoint.width <= 1460}" v-resize="onResize">
           <v-col class="ma-0 pa-0" cols="12" sm="6" v-show="($vuetify.breakpoint.width <= 1460 && right_column_view === 0) || $vuetify.breakpoint.width > 1460" :class="{'px-0 pt-0' : $vuetify.breakpoint.xs, ' pt-4 pl-4' : !$vuetify.breakpoint.xs}"> -->


        <v-row :class="responsiveClasses" v-resize="onResize">
           <v-col class="ma-0 pa-0" cols="12" sm="6" v-show="($vuetify.breakpoint.width <= 837 && right_column_view === 0) || $vuetify.breakpoint.width > 837"  :class="{'px-0 pt-0' : $vuetify.breakpoint.width <= 480, ' pt-0' : $vuetify.breakpoint.width > 837, ' pt-0 pl-4' : $vuetify.breakpoint.width > 1259}">
                <overview-charges-widget :lease_change="is_loading" @takeAction="takeAction" @addLease="addLease" style="height:auto;" class="mb-6" :leases="contactLeases" :contact="contact" :property-view="propertyView" :payments="payments" :credits="credits"></overview-charges-widget>
                <div v-if="currentLeases">
                    <overview-lease-widget
                        v-for="(l, i) in currentLeases"
                        :payment_methods="payment_methods"
                        :key="'current_lease' + i"
                        :lease_change="is_loading"
                        @takeAction="takeAction"
                        @editLease="$emit('editLease')"
                        @transfer="emitTransfer"
                   s      @moveOut="emitMoveOut"
                        @merchandise="emitMerchandise"
                        style="height:auto;"
                        class="mb-6"
                        :lease="l"
                        :contact="contact"
                        @auctionSale="showAuctionModal"
                        @processPayment="setAuctionPayment"
                        @refundAuction="refundAuction"
                        :property-view="propertyView"
                        @view-auction="showAuctionModal(l, true)"
                        :delinquency-id="checkIfDelinquent(l)"
                    >
                    </overview-lease-widget>
                </div>
                <v-expansion-panels v-if="closedLeases.length > 0" v-model="closed_leases_panel" multiple flat class="hb-expansion-panel">
                    <v-expansion-panel class="hb-cloud-lighter hb-closed-lease-background">
                        <v-expansion-panel-header class="hb-default-font-size ma-0 py-0 px-4 hb-closed-lease-dropdown-header" color="#F9FAFB">
                            <span class="hb-text-light text-subtitle-2">CLOSED LEASES ({{closedLeases.length}})</span>
                        </v-expansion-panel-header>
                        <v-divider class="my-0 pa-0 mx-0"></v-divider>
                        <v-expansion-panel-content class="pa-0 ma-0" v-if="closedLeases">
                            <overview-lease-widget
                                class="mt-4 mb-6"
                                v-for="(l, i) in closedLeases"
                                closed="true"
                                :key="'close_lease' + i"
                                :lease_change="is_loading"
                                @editLease="$emit('editLease')"
                                @transfer="emitTransfer"
                                @moveOut="emitMoveOut"
                                @merchandise="emitMerchandise"
                                style="height:auto;"
                                :lease="l"
                                :contact="contact"
                                @auctionSale="showAuctionModal"
                                @processPayment="setAuctionPayment"
                                @refundAuction="refundAuction"
                                :property-view="propertyView"
                                @view-auction="showAuctionModal(l, true)"
                                :delinquency-id="checkIfDelinquent(l)"
                            >
                            </overview-lease-widget>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>

            <v-col class="ma-0 pa-0" cols="12" sm="6" :class="{'pl-0 pr-0' : $vuetify.breakpoint.width <= 480, 'pt-0' : $vuetify.breakpoint.width > 480 , 'pl-0 pr-0' : $vuetify.breakpoint.width <= 837 && $vuetify.breakpoint.width > 480, 'pl-6' : $vuetify.breakpoint.width > 837}">
                <hb-tabs-responsive v-if="$vuetify.breakpoint.width >= 837" background-color="#F9FAFB" class="hb-text-light"
                    color="#101318" v-model="right_column_view" show-arrows>
                    <template v-slot:tabs>
                        <tab v-for="(tab, i) in tenantProfileTabs" :hide="tab.hide || false" :label="tab.label" :key="tab.key"
                            class="text-capitalize" :badgeCount="tab.badgeCount || null"
                            :badgeColor="tab.badgeColor || null" :ripple="false">
                        </tab>
                    </template>
                </hb-tabs-responsive>
                <v-divider class="mb-2" v-if="$vuetify.breakpoint.width > 837"></v-divider>
                <!--
                <span v-show="$vuetify.breakpoint.xs && right_column_view === 0">
                    <overview-charges-widget :lease_change="is_loading" @takeAction="takeAction" @addLease="addLease" style="height:auto;" class="mt-4 mb-6" :leases="contactLeases" :contact="contact" :property-view="propertyView"></overview-charges-widget>
                    <overview-lease-widget v-if="currentLeases" v-for="(l, i) in currentLeases" :key="'current_lease_mobile' + i" class="mt-4 mb-6" :lease_change="is_loading" @takeAction="takeAction" @editLease="$emit('editLease')" @transfer="$emit('transfer', lease)" @moveOut="emitMoveOut" style="height:auto;" :lease="l" :contact="contact"></overview-lease-widget>

                    <v-expansion-panels v-if="closedLeases.length > 0" v-model="closed_leases_panel" multiple flat class="hb-expansion-panel">
                        <v-expansion-panel class="hb-cloud-lighter hb-closed-lease-background">
                            <v-expansion-panel-header class="hb-default-font-size ma-0 py-0 px-4 hb-closed-lease-dropdown-header" color="#F9FAFB">
                                <span class="hb-text-light text-subtitle-2">CLOSED LEASES ({{closedLeases.length}})</span>
                            </v-expansion-panel-header>
                            <v-divider class="my-0 pa-0 mx-3"></v-divider>
                            <v-expansion-panel-content class="pa-0 ma-0">
                                <overview-lease-widget v-if="closedLeases" class="mt-4 mb-6" v-for="(l, i) in closedLeases" closed="true" :key="'close_lease_mobile' + i" :lease_change="is_loading" @editLease="$emit('editLease')" @transfer="$emit('transfer', lease)" @moveOut="emitMoveOut" style="height:auto;" :lease="l" :contact="contact"></overview-lease-widget>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </span>
                -->
                
                <div v-show="right_column_view === 1" class="pa-0 pt-0 mb-6 communication-tenant-profile" :class="{'mt-4' : $vuetify.breakpoint.width <= 480, 'mt-4' : $vuetify.breakpoint.width > 1259}">
                    <!--BCT : CHR-151 -> Added 'currentPropertyId' prop.-->
                    <chat-window 
                    v-show="right_column_view === 1"
                    :lease_change="is_loading" 
                    chatWindowHeight="large" 
                    :dense="true" 
                    :no-header="true" 
                    :InteractionObj="contact" 
                    :from="interactionContext"
                    :context="interactionContext"
                    :currentPropertyId="propertyView"
                    @itemCount="updateItemCount"
                    ref="TenantChatWindowRef"
                    fromTenantProfile
                    >
                    </chat-window>
                </div>
                
                <contact-tasks  :class="{'mt-4' : $vuetify.breakpoint.width <= 480 && $vuetify.breakpoint.width > 1259}" v-show="right_column_view === 2" :activeView.sync="activeView" :pendingMoveIn="pending" :contact="contact" :property-view="propertyView"/>
                <contact :class="{'mt-4' : $vuetify.breakpoint.width <= 480 && $vuetify.breakpoint.width > 1259}" v-show="right_column_view === 3" :contact="contact" :property-view="propertyView" fromTenantProfile></contact>
                <delinquency :class="{'mt-4' : $vuetify.breakpoint.width <= 480 && $vuetify.breakpoint.width > 1259}" v-show="right_column_view === 4" :contact="contact" :active_delinquencies="delinquencies"></delinquency>
                <!-- <rent-timeline class="mt-4" v-if="right_column_view === 5" :light="true" width="auto" height="auto"></rent-timeline> -->
            </v-col>
        </v-row>

      <credit v-if="action ==='credit' && show_action_model" v-model="show_action_model" :property_id="contactLeases[0].Unit.property_id ? contactLeases[0].Unit.property_id : ''" :contact_id="contact.id" :credits="credits" @close="show_action_model = false" @notification="setNotification"></credit>

      <refund-dialog v-if="action ==='refund' && show_refund_model" v-model="show_refund_model" :property_id="contactLeases[0].Unit.property_id ? contactLeases[0].Unit.property_id : ''" :contact_id="contact.id" :payments="payments" @close="show_refund_model = false" @notification="setNotification" :unallocated="unallocated"></refund-dialog>

<!--
      <auction-dialog
        v-if="show_auction_model"
        v-model="show_auction_model"
        :contact_id="contact.id"
        :auctionLease.sync="auctionLease"
        :message="getAuctionMessage(auctionLease)"
        @close="show_auction_model = false, firstTab = false"
        @notification="setNotification"
        @processPayment="setAuctionPayment"
        :property_id="contactLeases[0].Unit.property_id ? contactLeases[0].Unit.property_id : ''"
        @moveOut="emitMoveOut"
        @refundAuction="refundAuction"
        :auction_refund_id="auction_refund_id"
        :first-tab="firstTab"
        :delinquency-id="delinquency_id"
      ></auction-dialog>
-->
        <v-dialog
            v-model="showAuctionPaymentModal"
            :width="720"
            :persistent="true"
            :scrollable="true"
            slot="body"
            v-if="showAuctionPaymentModal"
        >
            <edit-payment
                :payment_id="auction_payment_id"
                :auctionPayment="true"
                @close="showAuctionPaymentModal=false"
            >
            </edit-payment>
        </v-dialog>
        <!-- Refund Modal -->
          <hb-modal
            size="medium"
            title="Refund Cleaning Deposit"
            show-help-link
            v-model="refundAuctionFlag"
            v-if="refundAuctionFlag"
          >
            <template v-slot:content>
                <status
                    @resetStatus="errorClear($options.name)"
                    v-if="errorHas($options.name)"
                    :message="errorGet($options.name)"
                    status="error"
                    class="pa-3"
                ></status>
              <v-row class="pa-0 ma-0">
                  <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                      Refund From
                  </v-col>
                  <v-col cols="8" class="ma-0 pt-4 pl-6">
                    <v-select
                      v-model="refund.paymentFrom"
                      :items="paymentTypes"
                      item-value="value"
                      item-text="text"
                      name="payment_type"
                      label="Select a payment"
                      hide-details
                    ></v-select>
                  </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pa-0 ma-0">
                  <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                      Amount
                  </v-col>
                  <v-col cols="8" class="ma-0 pt-4 pl-6">
                    <v-text-field
                      :disabled="true"
                      :error-messages="errors.collect('refund_amount')"
                      :hide-details="!errors.collect('refund_amount').length"
                      label="Refund Amount"
                      flat
                      id="refund_amount"
                      name="refund_amount"
                      data-vv-as="refund amount"
                      v-model="refund.amount"
                      v-validate="'required|integer'"
                    ></v-text-field>
                    <span class="caption" style="color: #637381;">(Total Available: {{scheduled_auction.cleaning_deposit | formatMoney}})</span>
                  </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pa-0 ma-0">
                  <v-col cols="4" class="py-4 px-6 ma-0 hb-cloud-light font-weight-medium">
                      Refund To
                  </v-col>
                  <v-col cols="8" class="ma-0 pt-4 pl-6 text-capitalize">
                    {{ refund_option.text }}
                  </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" :disabled="isRefundAmount ||disableRefund" @click="processRefund">Refund</hb-btn>
            </template>
          </hb-modal>
          <!-- Refund Modal -->


            <!-- Delinquencies Modals -->
        <hb-modal
            size="medium"
            title="Resume Delinquency Automation"
            show-help-link
            v-model="show_resume_confirm"
            confirmation
            @close="cancelResume"
        >
            
            <template v-slot:content>
                <div class="py-4 px-6">
                    You are about to unpause your tenant's delinquency automation.
                    <br /><br />
                    Having a delinquency automation in place ensures that your property has a delinquency process that sends out the appropriate letters and notices to delinquent tenants and notifies you when you need to take delinquency related actions. 
                    <br /><br />
                    Unpausing this automation will pick up this tenant's delinquency process at the last stage the tenant was at when you originally paused it. 
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="primary" @click="confirmResumeDelinquency">Resume Delinquency</hb-btn>
            </template>
        </hb-modal>

         <hb-modal
            size="medium"
            :title="canPause ? 'Pause Delinquency Automation': 'Permission Required'"
            show-help-link
            v-model="show_pause_confirm"
            confirmation
            :footer-off="!canPause"
            @close="cancelPause"
        >
      
            <template v-slot:content>
                <div class="py-4 px-6" v-if="canPause">
                    <strong>You are about to pause the delinquency automation assigned to this tenant. </strong>
                    <br /><br />
                    Having a delinquency automation in place ensures that your property has a delinquency process that sends out the appropriate letters and notices to delinquent tenants and notifies you when you need to take delinquency related actions.  
                    <br /><br />
                    Pausing this process means you are choosing to leave Hummingbird’s automated process and will continue with your own manual process.
                    <br /><br />
                    Pausing this automation freezes it in its most recent stage. Should you resume this automation, the automation will pick up just as far along in its delinquency process as it was when you originally paused it. 
                    <br /><br />
                    <v-textarea
                        v-model="pause_reason"
                        v-validate="'required'"
                        data-vv-scope="pause"
                        id="reason"
                        name="reason"
                        :error="errors.collect('pause.reason').length > 0"
                        label="Reason For Pausing (Required)*"
                        rows="2"
                        autofocus
                    >

                    </v-textarea>
                </div>
                <div class="py-4 px-6" v-else>
                   <p> <strong>You do not have permission to perform this action. </strong><br /><br />              
                    Please contact your manager if you need assitance in resuming the delinquency automation.</p>
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn color="destructive" @click="confirmPauseDelinquency">Pause Automation</hb-btn>
            </template>
        </hb-modal>
            <!-- /Delinquencies Modal -->
    </div>
</template>

<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import api from '../../assets/api.js';
    import moment from 'moment';
    import { EventBus } from '../../EventBus.js';
    import OverviewLeaseWidget from '../leases/OverviewLeaseWidget.vue';
    import OverviewChargesWidget from '../leases/OverviewChargesWidget.vue';
    import OverviewActivitiesWidget from '../leases/OverviewActivitiesWidget.vue';
    import OverviewCommunicationsWidget from '../leases/OverviewCommunicationsWidget.vue';
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import ChatWindow from '../communication_center/ChatWindow.vue';
    import Credit from '../leases/Credit.vue';
    import RefundDialog from '../leases/RefundDialog.vue';
    import AuctionDialog from '../leases/AuctionDialog.vue';
    import Contact from './Contact.vue';
    import ContactTasks from './ContactTasks.vue';
    import TasksList from '../includes/TaskCenter/TasksList';
    import EditPayment from '../payments/EditPayment';
    import Delinquency from './Delinquency.vue';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';
    import AUTOMATION from '@/constants/automation.js';

    export default {
        name: 'ContactOverview',
        mixins: [ notificationMixin ],
        data() {
            return {
                lease: {
                    id: '',
                    start_date: '',
                    end_date:'',
                    bill_day: '',
                    rent : ''
                },
                delinquencies: [],
                show_resume_confirm: false,
                show_pause_confirm: false,
                selected_delinquency: null,
                pause_reason:null, 
                is_loading: false,
                interactionContext: 'lease',
                show_action_model: false,
                show_refund_model: false,
                show_auction_model: false,
                auctionLease: {},
                action: false,
                tenants: [],
                right_column_view: 0,
                communications_count: 0,
                closed_leases_panel: [],
                credits: [],
                // payments contain reserved balance and prepay
                payments: [],   
                notification: false,
                type: '',
                message: '',
                unallocated: false,
                
                activeView: 'taskCenter',
                tempTask: [],
                urlEvents: {
                    followUp: ['lead_follow_up', 'new_web_reservation', 'reservation_follow_up', 'new_web_lead', 'walkin_lead_reservation', 'new_reservation', 'new_lead', 'cart_abandonment', 'coupon_clicks', 'hotlead', 'reservation_failure'],
                    collectionCall: ['collection_calls'],
                    moveOut: ['move_out', 'move_out_cancelled', 'move_out_confirmation']
                },
                urlEventIds: {},
                disableButtons: false,
                isLoaded: false,
                gTasksCount: '',
                filteredTasks: [],
                showAuctionPaymentModal: false,
                auction_payment_id: '',
                paymentTypes: [
                    { text: "Cleaning Deposit", value: "cleaning_deposit" }
                ],
                refund: {
                    paymentFrom: 'cleaning_deposit',
                    amount: 0,
                    paymentTo: ''
                },
                refundAuctionFlag: false,
                refund_option: {
                    text: '',
                    value: 0
                },
                auctionPayment: {},
                scheduled_auction: {},
                auction_refund_id: null,
                showAutoReconcileConfirmation: false,
                hideAutoReconcileOption: false,
                pageCreated: false,
                disableRefund: false,
                firstTab: false,
                delinquency_id: '',
            }
        },
        async created() {
            this.AUTOMATION = AUTOMATION;
            this.is_loading = true;
            EventBus.$on('insurance_updated', this.insuranceUpdatedNote);
            EventBus.$on('service_updated', this.serviceUpdatedNote);
            // TODO: Refactor, Move payments to store as its used by several components
            EventBus.$on('refetch_overview_payments', this.fetchPayments);
            this.getEventTypes('task');
            if(this.contact.id && this.propertyView) {
                this.fetchPayments();
            };
            this.pageCreated = true;
            EventBus.$on('delinquency_actions_update', this.updateDelinquencyActions);
            EventBus.$on('update_charges_widget', this.fetchPayments);
            EventBus.$on('lease_payment_saved', this.fetchPayments);
            EventBus.$on('payment_saved', this.fetchPayments);
            EventBus.$on('invoice_saved', this.fetchPayments);
            EventBus.$on('lease_edited', this.fetchPayments);
            this.fetchDeliquencies();
        },
        destroyed(){
            EventBus.$off('insurance_updated', this.insuranceUpdatedNote);
            EventBus.$off('service_updated', this.serviceUpdatedNote);
            EventBus.$off('refetch_overview_payments', this.fetchPayments);
            EventBus.$off('update_charges_widget', this.fetchPayments);
            EventBus.$off('lease_payment_saved', this.fetchPayments);
            EventBus.$off('payment_saved', this.fetchPayments);
            EventBus.$off('invoice_saved', this.fetchPayments);
            EventBus.$off('lease_edited', this.fetchPayments);
            EventBus.$off('delinquency_actions_update', this.updateDelinquencyActions);
            this.resetTasks();
        },
        components:{
            RefundDialog,
            AuctionDialog,
            Credit,
            Status,
            OverviewLeaseWidget,
            OverviewChargesWidget,
            OverviewActivitiesWidget,
            OverviewCommunicationsWidget,
            ChatWindow,
            Contact,
            ContactTasks,
            TasksList,
            EditPayment,
            Delinquency,
            // RentTimeline
        },
        computed:{
            ...mapGetters({
                admin: 'authenticationStore/getUserData',
                isAdmin: 'authenticationStore/isAdmin',
                permissions: 'authenticationStore/isAdmin',
                buttons: 'profileTasksStore/buttons',
                headers: 'profileTasksStore/headers',
                tasks: 'profileTasksStore/tasks',
                permissions: 'authenticationStore/rolePermission', 
                eventsTypes: 'profileTasksStore/eventTypes',
                checkPermissionProperties: 'authenticationStore/checkPermissionProperties',
            }),
            tenantProfileTabs(){
                return [{
                    label: 'Leases',
                    hide: this.$vuetify.breakpoint.width >= 837,
                    key: 'leases'
                },
                {
                    label: 'Communication',
                    key: 'communication',
                    badgeCount: this.communications_count ? this.communications_count : null,
                    badgeColor: "#F26500"
                },
                {
                    label: 'Tasks',
                    key: 'tasks',
                    hide: this.$vuetify.breakpoint.width <= 480,
                    badgeCount: this.tasksCount ? this.tasksCount : null,
                    badgeColor: "#F26500"
                },
                {
                    label: 'Tenant Info',
                    key: 'tenant_information'
                }, {
                    label: 'Delinquency',
                    key: 'delinquency'
                }]
            },
            accessConflict(){
                // Only do this for Noke Properties
                return this.contactLeases.filter(l => {
                    // If this is not a noke facility, retturn
                    let access = this.access.find(a => a.property_id === l.Unit.property_id);
                    return access && access.name === "Noke" && l.Unit.state.toLowerCase() === 'overlocked' && this.access.status;
                });
            },
            // responsiveClasses() {
            //     if (this.$vuetify.breakpoint.xs) {
            //         return 'px-3';
            //     } else if(this.$vuetify.breakpoint.width >= 600 && this.$vuetify.breakpoint.width <= 1259) {
            //         return 'pr-6';
            //     } else {
            //         return 'pr-9';
            //     }
            // },
            header(){
                let head = this.headers.filter(header => header.action === this.activeView);
                if(this.activeView === 'seeList' && this.tasks.length) {
                    head[0].title = this.tasks[0].task.Event.event_type.name;
                }
                return head[0];
            },
            responsiveClasses() {
                if (this.$vuetify.breakpoint.width <= 480) {
                    return 'px-3';
                } else if(this.$vuetify.breakpoint.width > 480 && this.$vuetify.breakpoint.width <= 1259) {
                    return 'pr-6 pl-4';
                } else {
                    return 'pr-9';
                }
            },
            tasksCount() {
                return this.tasks.length + this.pending.length;
            },
            formattedTasks(){
                this.tempTask = [...this.tempTask, ...this.tasks];
                let tasksArr = [];
                if (this.tempTask && this.tempTask.length) {
                    this.tempTask.forEach( (sTask, index) => {
                        let task = this.validateTask(sTask);
                        if (task) {
                            tasksArr.push(task)
                        }
                    });
                }
                return tasksArr;
            },
            isAuctionLeasePresent() {
                return this.contactLeases.find(lease => lease.auction_status && lease.auction_status.length !== 0 && lease.auction_status != null && lease.auction_status != 'complete');
            },
            auctionLeases() {
                if(this.isAuctionLeasePresent) {
                    return this.contactLeases.filter(lease => (lease.auction_status && lease.end_date == null && lease.auction_status != 'complete'));
                }
                return [];
            },
            canPause(){
                return this.permissions('pause_delinquency');
            },
            getMobileClasses() {
                return ($vuetify.breakpoint.width <= 480 && $vuetify.breakpoint.width > 1259) ? 'mt-4' : ''; 
            },
            /*
            isCurrentLease(){

                if(this.lease.moved_out && moment(this.lease.moved_out, 'YYYY-MM-DD').endOf('day').isBefore(moment().startOf('day'))) return false;
                return moment(this.lease.start_date, 'YYYY-MM-DD').startOf('day') <= moment().startOf('day') && (this.lease.end_date == null || moment(this.lease.end_date, 'YYYY-MM-DD').startOf('day') > moment().startOf('day'));
            },
            isPastLease(){
                if(this.lease.moved_out) return true;
                if(this.lease.end_date != null && moment(this.lease.end_date, 'YYYY-MM-DD').startOf('day') <= moment().startOf('day')) return true;

                return false;
            },
            isFutureLease(){
                return moment(this.lease.start_date, 'YYYY-MM-DD').startOf('day') > moment().startOf('day');

            },
            */
            currentLeases(){
                const currentLeases = this.contactLeases.filter(l => l.end_date === null || moment(l.end_date).startOf('day') > moment().startOf('day'));
                this.setLeases({
                    leases: currentLeases,
                    leases_status: 'current'
                });
                return currentLeases;
            },
            closedLeases(){
                const closedLeases = this.contactLeases.filter(l => moment(l.end_date).startOf('day') <= moment().startOf('day') );
                this.setLeases({
                    leases: closedLeases,
                    leases_status: 'closed'
                });
                return closedLeases;
            },
            totalCredits(){
                return this.credits.reduce((a, p) => a + Math.round(p.amt_remaining*1e2)/1e2, 0);
            },
            totalReservedBalance(){
                return this.payments.reduce((a, p) => a + Math.round(p.amt_remaining*1e2)/1e2, 0);
            },
            showAutoReconcilation() {
                const openInvoicesPresent = this.contact && this.contactLeases && this.contactLeases.some(lease => lease.has_open_invoice);

                const creditsOrReservedBalancePresent = this.totalCredits > 0 || this.totalReservedBalance > 0;

                if(!this.hideAutoReconcileOption && openInvoicesPresent && creditsOrReservedBalancePresent) {
                    return true;
                }
                
                return false;
            },
            isRefundAmount() {
                return this.refund.amount ? false : true;
            },
        },
        filters:{
             delinquency_title(d){
                 if(d.status === "active" && !d.Pauses.length){
                     return "Delinquency Stage:"; 
                 }
                 else if (d.status === "active" && d.Pauses.length){
                     let pause = d.Pauses[d.Pauses.length - 1]; 
                    return `Delinquency resumed on ${moment.utc(pause.ended_at).local().format('MMM DD, YYYY @ hh:mma')} ${pause.ResumedBy.first} ${pause.ResumedBy.last}:`
                 }  else if (d.status === 'paused'){
                     let pause = d.Pauses[d.Pauses.length - 1]; 
                     return `Delinquency paused on ${moment.utc(pause.created_at).local().format('MMM DD, YYYY @ hh:mma')} by ${pause.PausedBy.first} ${pause.PausedBy.last}`;
                 }
             },
             delinquency_message(d) {
                if (d.status === 'paused'){
                    return '';
                }

                let latestDelinquencyTrigger;
                for(let i = 0; i < d.Timeline.length; i++) {
                    const delinquencyTrigger = d.Timeline[i];

                    if(moment().format('x') >= moment(delinquencyTrigger.execution_date).format('x')) {
                        if(!latestDelinquencyTrigger || moment(delinquencyTrigger.execution_date).format('x') > moment(latestDelinquencyTrigger.execution_date).format('x')) {
                            latestDelinquencyTrigger = delinquencyTrigger;
                        }
                    }
                }
                 
                if(latestDelinquencyTrigger == null) {
                    return `Day 1 - Tenant Rent Assessment`;
                }

                return `Day ${latestDelinquencyTrigger.start} - ${latestDelinquencyTrigger.name} for Space #${d.Lease.Unit.number}`; 
            },
        }, 
        methods: {
            ...mapActions({
                getEvents: 'profileTasksStore/getEvents',
                getEventTypes: 'profileTasksStore/getEventTypes',
                resetTasks: 'profileTasksStore/resetTasks',

            }),
            ...mapMutations({
                setLeases: 'tenantProfile/setLeases'
            }),
            updateDelinquencyActions(payload) {
                const { leases } = payload;
                const isLeaseUpdated = this.contactLeases.some(cl => leases.some(l => l.id === cl.id));
                if(isLeaseUpdated) {
                    this.fetchDeliquencies();
                }     
            },
            setAuctionPayment(payment_id) {
                this.showAuctionPaymentModal = true;
                this.auction_payment_id = payment_id;
            },
            async processRefund(){
                this.disableRefund = true;
                const status = await this.$validator.validateAll();
                if(!status) return
                // refund
                var data = {
                    amount: parseFloat(this.refund.amount)
                }
                await api.post(this, api.LEASE_AUCTION + this.scheduled_auction.id + '/refund', data).then(r => {
                    this.auction_refund_id = r.lease_auction_id;
                    EventBus.$emit('contact_updated',  this.contact_id);
                    EventBus.$emit('reservation_made');
                    this.refundAuctionFlag = false;
                });
                this.disableRefund = false;
            },
            async fetchPayment(){
                await api.get(this, api.GET_PAYMENT + this.scheduled_auction.payment_id).then(r => {
                    this.auctionPayment = r.payment;
                    this.getRefundOptions();
                });
            },
            getRefundOptions() {
                // Handling Credit payment refund option
                if(this.auctionPayment.method && this.auctionPayment.method === 'credit') {
                    this.refund_option = {
                    text: 'Credit Balance',
                    value: 0
                    }
                    return;
                }
                let text = ''
                if (['card', 'ach'].indexOf(this.auctionPayment.method) >= 0) {
                    text = this.auctionPayment.PaymentMethod.card_type + '****' +  this.auctionPayment.PaymentMethod.card_end
                } else {
                    text = this.auctionPayment.method
                }
                this.refund_option = {
                    text,
                    value: this.auctionPayment.PaymentMethod.id  || 1
                }
            },
            refundAuction(scheduledAuction) {
                this.refundAuctionFlag = true;
                this.scheduled_auction = scheduledAuction;
                this.refund.amount = scheduledAuction.cleaning_deposit;
                this.fetchPayment();
            },
            showAuctionModal(lease, view) {
                /*
                this.auctionLease = lease;
                if(lease){
                    let delinquencyMatch = this.delinquencies.find( i => lease.id === i.lease_id );

                    if(delinquencyMatch && delinquencyMatch.id){
                        this.delinquency_id = delinquencyMatch.id;
                    }
                }
                if(view === true){
                    this.firstTab = true;
                }
                this.show_auction_model = true;
                */
                EventBus.$emit('HB-Navigation:AuctionManager', lease, this.contact.id);

            },
            getAuctionAction(lease) {

                if(lease && lease.LeaseAuction && lease.LeaseAuction.auction_type === 'storage-treasures' && !lease.LeaseAuction.vendor_auction_id) {
                    return 'Review & Publish';
                } else {
                    switch (lease.auction_status) {
                        case 'schedule':
                            return 'Schedule Space for Auction';
                            break;
                        case 'scheduled':
                            return 'View';
                            break;
                        case 'auction_day':
                            return 'Add Auction Info';
                            break;
                        case 'auction_payment':
                            return 'Auction Payment';
                        case 'move_out':
                            return 'View Auction Sale';
                            break;
                        default:
                            break;
                    }
                }
                
            },
            getProtectedPropertyItemsMessage(lease) {
                switch (lease.auction_status) {
                    case 'schedule':
                        return 'Check space for Protected Property.';
                    default:
                        return '';
                }
            },
            getAuctionMessage(lease) {

                if(lease && lease.LeaseAuction && lease.LeaseAuction.auction_type === 'storage-treasures' && !lease.LeaseAuction.vendor_auction_id) {
                    return 'Space #'+ lease.Unit.number + ` is scheduled for online auction from ${this.$options.filters.formatLocalShortDate(moment(lease.LeaseAuction.scheduled_date).subtract(10, 'days'))} to ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                } else if(lease && lease.LeaseAuction && lease.LeaseAuction.auction_type === 'storage-treasures' && lease.LeaseAuction.vendor_auction_id && lease.auction_status && lease.auction_status === 'scheduled') {
                    return 'Space #'+ lease.Unit.number + ` is scheduled and published for online auction from ${this.$options.filters.formatLocalShortDate(moment(lease.LeaseAuction.scheduled_date).subtract(10, 'days'))} to ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                } else {
                    switch (lease.auction_status) {
                        case 'schedule':
                            return 'Schedule Space #'+ lease.Unit.number +' for Auction.';
                            break;
                        case 'scheduled':
                            return 'Space #'+ lease.Unit.number +
                                ` is scheduled for auction ending on ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                            break;
                        case 'auction_day':
                            return `Verify if auction for Space #${lease.Unit.number} has been closed and enter the bid amount.`;
                        case 'auction_payment':
                            return `Auction Winner info has been saved. Please take the auction payment.`;
                        case 'move_out':
                            return `Space #${lease.Unit.number} has been sold on ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                            break;
                        case 'auction_not_sold':
                            return `Auction for Space #${lease.Unit.number} was not sold - Auction ended ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                            break;
                        default:
                            break;
                    }
                }
            },
            validateTask(taskData){
                let taskObj = {
                    todo: taskData.task
                };
                let task = taskData.task;

                if (taskData.type === 'group' && taskData.Tasks && taskData.Tasks.length) {
                    taskObj.grouped_tasks = taskData.Tasks;
                }

                if (
                    task
                    && Object.keys(task).length > 0
                    && task.Event
                    && Object.keys(task.Event).length
                    && task.Event.event_type
                    && Object.keys(task.Event.event_type).length) {
                        taskObj.event_type = task.Event.event_type;
                        if (task.Event.Contact && Object.keys(task.Event.Contact).length) {
                            taskObj.contact = task.Event.Contact;
                        }
                        if (task.Event.Lease) {
                            taskObj.unit = task.Event.Lease.Unit;
                            taskObj.lease_id = task.Event.Lease.id;
                            if (!taskObj.contact && task.Event.Lease.Tenants && task.Event.Lease.Tenants.length && task.Event.Lease.Tenants[0].Contact) {
                                taskObj.contact = task.Event.Lease.Tenants[0].Contact;
                            }
                        }
                }
                return ( !taskObj.grouped_tasks && Object.keys(taskObj).length > 0 ) || ( taskObj.grouped_tasks && Object.keys(taskObj).length > 1 ) ? taskObj : null;
            },
            setIds(){
                let types = this.eventsTypes;
                Object.keys(this.urlEvents).forEach((key, index) => {
                    let arr = [];
                    Object.values(this.urlEvents[key]).forEach( ename => {
                        let found = types.find( arrEl => arrEl.slug == ename);
                        if (found) {
                            arr.push(found.id);
                        }
                    });
                    this.urlEventIds[key] = arr;
                });
            },
            fetchTasks(){
                this.activeView = 'taskCenter';
            },
            takeAction(action, payload, payload2){
                switch(action){
                    case 'payment':
                        EventBus.$emit('make_payment', {
                          property_id: this.propertyView,
                          contact_id: this.contact.id
                        });
                        break;
                    case 'credit':
                        this.credits = payload;
                        this.show_action_model = true;
                        this.action = action;
                        break;
                    case 'refund':
                        this.payments = payload;
                        this.show_refund_model = true;
                        this.action = action;
                        if(payload2 && payload2 === 'unallocated'){
                            this.unallocated = true;
                        } else {
                            this.unallocated = false;
                        }
                        break;
                    case 'merchandise':
                        EventBus.$emit('sell_merchandise', {
                            // lease_id: this.contactLeases[0].id,
                            property_id: this.propertyView,
                            contact: this.contact
                        });
                        break;
                }
            },

            async addLease(){
                await this.$store.dispatch('onBoardingStore/getContactInfo', {contact_id: this.$route.params.contact_id,click_event: 'addSpace'});
                EventBus.$emit('new_lead');
                // EventBus.$emit('new_lead');
            },

            updateItemCount(payload){  
                this.communications_count = payload;
            },
            setNotification(payload){
                this.type = payload.type;
                this.message = payload.message;
                this.notification = true;
            },
            insuranceUpdatedNote(){
                this.type = 'success';
                this.message = 'Updated Insurance Plan.';
                this.notification = true;
            },
            serviceUpdatedNote(){
                this.type = 'success';
                this.message = 'Updated Services.';
                this.notification = true;
            },
            onResize(){

                // if(!this.$vuetify.breakpoint.xs && this.right_column_view == 0){
                //     this.right_column_view = 1;
                // }
                //EventBus.$emit('closeDrawer');
                if(this.$vuetify.breakpoint.width > 837 && this.right_column_view == 0){
                    //console.log('is in if', this.$vuetify.breakpoint.width)
                    this.right_column_view = 1;
                }

                // if(this.$vuetify.breakpoint.width <= 837 && this.right_column_view == 1){
                //     //console.log('is in if', this.$vuetify.breakpoint.width)
                //     this.right_column_view = 0;
                // }
            },
            emitTransfer(payload){
                this.$emit('transfer', payload);
            },
            emitMoveOut(payload){
                this.$emit('moveOut', payload);
            },
            emitMerchandise(payload, params){
                payload = {
                    ...payload,
                    tab_type: params
                }
                this.$emit('merchandise', payload);
            },
            async fetchPayments() {
                // TODO: Move this to store                
                // await api.get(this, api.CONTACTS + this.$route.params.contact_id + '/credits', { property_id: this.propertyView }).then(r => {
                //     this.payments = r.payments.filter(payment => 
                //         payment.credit_type === 'payment'
                //     );
                //     this.credits = r.payments.filter(payment => 
                //         payment.credit_type === 'credit'
                //     );
                // });
            },
            async fetchDeliquencies(){
                let r = await api.get(this, api.CONTACTS + this.$route.params.contact_id + '/delinquencies' + '?property_id=' + this.propertyView);
                
                this.delinquencies = r.delinquencies;
                await this.reveserDeliquencyActions();
            },
            reveserDeliquencyActions() {
                this.delinquencies.map(d => {
                    d.Actions.reverse();
                });
            },

            async pauseDelinquency(d){
                this.selected_delinquency = d; 
                this.show_pause_confirm = true;
            }, 

            async confirmPauseDelinquency(){
                let validation = await this.$validator.validateAll('pause');

                // if(!validation) return;
                try {
                    let r = await api.put(this, api.LEASES + this.selected_delinquency.lease_id + '/delinquency/' + this.selected_delinquency.id + '/pause', { reason: this.pause_reason })
                    this.fetchDeliquencies();
                    this.show_pause_confirm = false;
                    this.pause_reason = null; 

                    this.showMessageNotification({ type: 'success', description: "The delinquency process has been paused on " + moment().format('MMM Do, YYYY @ h:mma') + ' by ' + this.admin.first + ' ' + this.admin.last })


                } catch(err) {
                    this.showMessageNotification({ description: err })
                }

            },
            async resumeDelinquency(d){
               this.selected_delinquency = d; 
                this.show_resume_confirm = true;
            },

            async confirmResumeDelinquency(){
                try {
                    let r = await api.put(this, api.LEASES + this.selected_delinquency.lease_id + '/delinquency/' + this.selected_delinquency.id + '/resume', { property_id: this.propertyView })
                    this.fetchDeliquencies();
                    this.show_resume_confirm = false;
                    this.showMessageNotification({ type: 'success', description: "The delinquency process resumed on " + moment().format('MMM Do, YYYY @ h:mma') + ' by ' + this.admin.first + ' ' + this.admin.last })

                 } catch(err) {
                    this.showMessageNotification({ description: err })
                }
            },
            cancelPause(){
                this.selected_delinquency = null; 
                this.show_pause_confirm = false;
                this.pause_reason = null; 
            }, 
            cancelResume(){
                this.selected_delinquency = null; 
                this.show_resume_confirm = false;
            }, 
            async autoReconcile() {
                const data = {
                    property_id: this.propertyView
                };

                const { id: contact_id } = this.contact;

                try {
                    let result = await api.post(this, api.CONTACTS + contact_id + '/auto-reconcile', data);

                    this.showAutoReconcileConfirmation = false;

                    this.setNotification({
                      type: 'success',
                      message: 'Auto-reconcile Done Successfully.'
                    });

                    await EventBus.$emit('contact_updated', contact_id);
                    await this.fetchPayments();
                } catch(err) {
                    this.setNotification({
                      type: 'caution',
                      message: err
                    });
                }
            },
            closeAutoReconcile() {
                this.hideAutoReconcileOption = true;
            },
            phoneNumberClicked() {
                this.$refs.TenantChatWindowRef?.phoneNumberClicked();
            },
            checkIfDelinquent(lease){
                if(lease && this.delinquencies.length){
                    let delinquencyMatch = this.delinquencies.find( i => lease.id === i.lease_id );

                    if(delinquencyMatch && delinquencyMatch.id){
                        return delinquencyMatch.id;
                    }
                } else {
                    return null;
                }
            }
        },
        props: ['showAside', 'contact', 'contactLeases', 'propertyView', 'pending', 'payment_methods', 'access'],
        watch: {
            '$route.params.contact_id': async function() {
                if(this.pageCreated){
                    this.notification = false;
                    this.type = '';
                    this.message = '';
                    await this.fetchPayments();
                    this.fetchDeliquencies();
                }
            },
            async propertyView() {
                if(this.pageCreated){
                    this.notification = false;
                    this.type = '';
                    this.message = '';
                    await this.fetchPayments();
                    await this.fetchDeliquencies();
                }
            },
            '$route': function(){
                this.notification = false;
                this.type = '';
                this.message = '';
            },
            activeView(value){
                this.tempTask = [];
                this.resetTasks();
            },
            eventsTypes(value) {
                if (value) {
                    this.setIds();
                }
            },
            /*
            contact(value) {
                if(value) {
                    this.fetchPayments();
                }
            }
            */
        }
    }
</script>

<style scoped lang="scss">

// .communication-tenant-profile >>> .hb-communication-info-wrapper-overflow-handler .hb-communication-default-slot-preview-overflow * {
//         white-space: wrap!important;
//     }

    .overview-content-view{
        height: 100%;
        max-width: 100%;
    }

    .overview-page{
        display: flex;
        align-items: stretch;
    }
    .overview-page-main{
        flex: 1 0 45%;
        padding-right:25px;
    }

    .overview-page-sidebar{
        flex: 0 0 55%;
    }

    .overview-page-sidebar .table-head {
        background-color: #f5f7f8;
        color: #354750;
    }

    .overview-section{
        padding: 5px;
        position: relative;
    }
    .overview-section-holder{
        margin-bottom: 20px;
        padding-bottom: 0px;
        border: 1px solid #e2e2e2;
        background: white;
        box-shadow: 1px 1px 4px 0px #dee5e7;
    }

    .overview-section-holder.hbError{
        border: 1px solid #D91E18;
        /*background-color: #fbe8e7;*/
    }

    .overview-page-sidebar .overview-section-holder.hbError .table-head{
        /*background-color: #f4bbb9;*/
        font-weight: bold;
    }

    .overview-page-sidebar .overview-section-holder.hbError .table-cell{
        background: white;
    }

    .overview-section-holder.hbSuccess{
        border: 1px solid #00B894;
        /*background-color: #e5f8f4;*/
    }

    .overview-section-holder.info{
        background-color: #f2f9fd;
        border: 2px solid #00b2ce;

    }

    .overview-section-header{
        padding: 15px;
    }

    .overview-section-header h4{
        color: #798f9a;
        font-size: 16px;
        line-height: 22px;
        padding: 0;
        margin: 0 0 10px 0;
    }
    .overview-section-header h2{
        padding: 0;
        margin: 0 0 10px 0;
        font-weight: 500;
    }

    .overview-section-header h3{
        color: #798f9a;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        margin: 0;
        padding: 0;
    }

    .overview-section-holder.hbError .overview-section-header h4{
        color: #D91E18;
    }
    .overview-section-holder.hbSuccess .overview-section-header h4{
        color: #00B894;
    }
    .overview-section-holder.info .overview-section-header h4{
        color: #00b2ce;
    }

    .overview-section-holder.hbError .overview-section-header p,
    .overview-section-holder.info .overview-section-header p,
    .overview-section-holder.hbSuccess .overview-section-header p{
        color: #263238;
    }


    .overview-section-title{
        font-size: 14px;
        margin-top: 0;
        color: #354750;
        margin-bottom: 0;
    }
    .loading-container{
        height:60px;
    }

    .total-col{
        flex: 0 0 100px;
    }

    .notification{
        margin-bottom:0;
    }

    .overview-section-description{
        padding: 15px 10px;
    }

    .float-right{
        float: right;
    }

    .need-to-sign-row{
        background: #f5f5f6;
        padding-left: 15px;
        padding-right: 15px;
    }


    .overview-callout{
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        padding: 15px 0;
        display: flex;

    }

    .overview-callout h2{
        font-weight: 400;
        margin:0;
    }
    .overview-callout h4{
        margin:0;
        font-weight: 400;
        font-size: 14px;
        color: #798f9a;
    }

    .callout-left,
    .callout-right{
        width: 50%;
        flex:0 0 50%;
        padding: 5px;
        text-align: center;
    }
    .callout-left{
        border-right:1px solid #e2e2e2;
    }


    .overview-details{
        padding: 20px 40px;
        font-size: 14px;
    }

    .overview-details .table-cell{
        font-weight: bold;
    }

    .overview-details .table-cell.light-txt{
        color: #798f9a;
        font-weight: 400;
    }

    .overview-icon {
        float: left;
        color: white;
        background-color: #354750;
        border: 1px solid #263238;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        line-height: 40px;
        margin-right: 17px;
        font-size: 12px;
    }


     .overview-section-header h3.large-std-callout{
        color: #00b2ce;
        font-size: 28px;
        font-weight: 300;
        margin-bottom: 5px;
    }

    .overview-section-header .sectioned{
        display: flex;
        width: 100%;

    }
    .overview-section-header .sectioned .header-section-right,
    .overview-section-header .sectioned .header-section-left{
       flex: 1;
    }

    .hb-closed-lease-dropdown-header {
        min-height: 40px !important;
    }

    .hb-closed-lease-background {
        background-color: transparent !important;
    }

    @media (max-width: 837px) {
        .overview-page,
        .overview-page-main,
        .overview-page-sidebar {
            display: block;

        }
        .overview-page-main{
            padding: 0px;
        }
    }

    // @media (max-width: 480px) {
    //     .overview-content-view{
    //         padding-left: 10px!important;
    //         padding-right: 10px!important;
    //     }
    // }

    

    @media (min-width: 481px) and (max-width: 837px) {
        .flex-none {
            flex:none;
        }
        .overview-content-view {
            width: 100%;
        }
        .overview-content-view .col-sm-6 {
            width: 100%!important;
            max-width: 100%!important;
            flex: 0 0 100%!important;
        }

    }


</style>
